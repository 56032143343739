// react imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TeremberlesPage from './TeremberlesPage';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import CarouselComponent from '../components/carousel/CarouselComponent.js'
import { MDBContainer } from 'mdb-react-ui-kit';
import tovabb from "../assets/media/icons/tovabb nyil.svg"
import {useSelector} from "react-redux";
import {OpenStreetMap} from "../components/openstreetmap/OpenStreetMap";
import {getReplaceText} from "../util/get-replace-text";


const InfoPageFooldal = () => {

  const navigate = useNavigate();

  const [sliderImages, setSliderImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [direction, setDirection] = useState(window.outerWidth);
  const { parentInfopage, infopages } = useSelector(state => state)

  const handleWindowSizeChange = () => {
    setDirection(window.visualViewport.width);
  };

  useEffect(() => {
    window.visualViewport.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => window.scrollTo({
      top: 0,
      behavior: "smooth"
    }), 100);
  }, [ parentInfopage ]);

  useEffect(() => {
      if (parentInfopage && infopages) {
          if (parentInfopage.title === "Szervezeti felépítés" || parentInfopage.title === "Felnőttképzés") {
              return navigate(`/infos/${ parentInfopage.crumbs.map(c => getReplaceText(c.title)).join('/') }`);
          }
          if (parentInfopage.title.toLowerCase() === "tatabánya kártya") {
              return navigate(`/tbkartya`);
          }
      }
  }, [parentInfopage, infopages])

  return (
    <>
    {parentInfopage && (
        <section className="kapcsolat-map-section" id ="kapcsolat-map-section" >
        <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
          <div className="kapcsolat-map-div">
            <div className="one-col-div">
              <div className="navigate-div" style={{ marginTop: 0 }}>
                <button onClick={() => { navigate('/home')}}>Főoldal</button>
                <img src={tovabb} alt="tovabb"/>
                <button>{parentInfopage.title}</button>
                
              </div>
              <div className="up-div">
                <TeremberlesPage infopageById={parentInfopage}/>
                <div className="helyszin-div">
                    {parentInfopage.address !== "" && ( <>
                      <div className="title">
                        <span>Helyszín:</span>
                      </div>
                      <div className="address">
                        <span>{parentInfopage.address}</span>
                      </div>
                    </>)}
                   
                    {parentInfopage.lat !== null && (
                      <div className="program-maps">
                        <div className="map-keret" id="map-keret">
                            <OpenStreetMap lat={parentInfopage.lat} lng={parentInfopage.lng} />
                        </div>
                      </div>
                    )}
                  </div>
                  {(parentInfopage.gallery.length > 0 || parentInfopage.youtubeEmbedUrl) && (
                    <div className="gallery">
                    {parentInfopage.gallery && parentInfopage.gallery.length > 0 &&(
                          <MDBContainer fluid className="p-0" style={{position: "relative"}}>
                            <div  className="gallery-title" >
                              <span>Galéria</span>
                            </div>
                            <CarouselComponent gallery={parentInfopage.gallery} openLightboxlick={() => {setSliderImages(parentInfopage.gallery); setOpen(!open)}}/>
                          </MDBContainer>
                    )}
                    {parentInfopage.youtubeEmbedUrl && (
                        <div className="video">
                          <h4>Videó</h4>
                          <iframe src={parentInfopage.youtubeEmbedUrl + "?autoplay=0&controls=1&loop=0"} allow="autoplay" title="myFrame"></iframe>
                        </div>
                    )}
                    </div>
                  )}

              </div>
            </div>
          </div>
        </MDBContainer>
        {direction > 811 && (
          <div className="background-div"/>
        )}

        </section>
    )}
   <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={sliderImages.map(k => {return ({src: k.imageUrl})})}
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
      />

    </>
  );
};

export default InfoPageFooldal;
