import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import HirlevelModal from "../modals/HirlevelModal";
import TbkartyaModal from "../modals/TbkartyaModal";
import {useSelector} from "react-redux";
import { useNavigation } from "react-router-dom";

function Layout() {

    const { eventPlace, modals } = useSelector(state => state);
    const navigation = useNavigation();
    const [ mainMinHeight, setMainMinHeight ] = useState(0);

    useEffect(() => {
        document.body.style.setProperty('--box-bg', eventPlace.color);
        document.body.style.setProperty('--box-font', eventPlace.id === 6 ? "'IM FELL English'" : 'Urbanist');
    }, [
        eventPlace
    ])

    useEffect(() => {
        const infoHeight = document.querySelector(`.information-bar`)?.clientHeight || 0;
        const navbarHeight = document.querySelector(`.navbar`)?.clientHeight || 0;
        const footerHeight = document.querySelector(`.agora-footer`)?.clientHeight || 0;
        const minHeight = window.innerHeight - (infoHeight + navbarHeight + footerHeight)
        if (window.innerWidth > 811)
            setMainMinHeight(minHeight < 0 ? 0 : minHeight)
    }, [navigation.location])

    return (
        <>
            <div className="layout">

                <Header/>
                <main style={{ minHeight: `${mainMinHeight}px`}}>
                    <Outlet/>
                </main>
                <Footer/>

                {modals.newsletter && (
                    <HirlevelModal visible={modals.newsletter} />
                )}
                {modals.card && (
                    <TbkartyaModal visible={modals.card} />
                )}
            </div>
        </>

    );
}

export default Layout;
