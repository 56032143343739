import {store} from "../redux/store";
import {setCurrentEventPlaceId} from "../redux/actions/setCurrentEventPlaceId";
import {getCurrentEventPlaceId} from "../util/event-place";
import {setEvent} from "../redux/actions/setEvent";
import {getEvent} from "../action/getEvent";
import {setDetailGuestEvents} from "../redux/actions/setDetailGuestEvents";
import {listGuestEvents} from "../action/listGuestEvents";

export const eventLoader = async (args) => {
    store.dispatch(setCurrentEventPlaceId({ eventPlaceId: getCurrentEventPlaceId() }));
    store.dispatch(setEvent(await getEvent(args.params.id)));
    store.dispatch(setDetailGuestEvents({ guestEvents: await listGuestEvents() }));
    return true;
}
