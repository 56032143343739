import {useEffect, useState} from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {BlurhashSliderImage} from "./BlurhashSliderImage";
import {formatEventDate} from "../../util/format-event-date";
import {slug} from "../../util/slug";

const SliderComponent = (props) => {

const [direction, setDirection] = useState(window.outerWidth);

moment.updateLocale('hu', {
    months : [
        "Január", "Február", "Március", "Április", "Május", "Június", "Július",
        "Augusztus", "Szeptember", "Október", "November", "December"
    ]
});
  const navigate = useNavigate();

  const handleWindowSizeChange = () => {
    setDirection(window.visualViewport.width);
  };

  useEffect(() => {
    window.visualViewport.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);


  const responsive = {
    
    desktop: {
        breakpoint: { max: 4000, min: 0 },
        items: 1,
        partialVisibilityGutter: 1
    }
  };

  if (!props.kiemeltProgramok || props.kiemeltProgramok.length === 0) {
      return <></>
  }

  return (<div className={`slider-carousel`} >
            <Carousel
                additionalTransfrom={0} 
                swipeable={true}
                draggable={false}
                showDots={true}
                responsive={responsive}
                infinite={true}
                renderArrowsWhenDisabled={true}
                renderDotsOutside={true}
                slidesToSlide={1}
                centerMode={false}
                keyBoardControl={true}
                autoPlay={props.kiemeltProgramok.length > 1 ? true : false}
                autoPlaySpeed={8000}
                customTransition="transform 1000ms ease-in-out"
                removeArrowOnDeviceType={props.kiemeltProgramok.length > 1 ? [] : ["desktop"]}
                transitionDuration={1000}
                containerClass={"carousel-container"}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                >
                    {props.kiemeltProgramok.map((k, n) => {return (
                        <div className={ `slider-div slider-div--position-${ k.sliderTextPosition }` }>
                            <BlurhashSliderImage {...k} direction={direction}/>
                            <div className={`blur-layer ${ props.blurImage ? `blur-layer--blur` : `` }`}></div>
                            <div className="felirat" onClick={() => navigate('/programok/programdetailpage/' + k.id + '/' + slug(k.title), {
                                state: {
                                    programId: k.id,
                                    program: k,
                                    categoryName: "Következő programok",
                                    linkText: "kovetkezoprogramok"
                                }
                            })}
                                 style={{cursor: "pointer"}}>
                                <div className="felirat-datum">
                                    <span>{ moment.unix(k.dateFrom).locale('hu').format('MMMM DD., ') + formatEventDate(k, true) }</span>
                                </div>

                                <div className="felirat-szoveg">
                                    <span className="title">{k.title}</span>
                                </div>
                            </div>
                        </div>
                    )
                    })}
            </Carousel>
          </div>
  );
};

export default SliderComponent;
