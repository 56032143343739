// react imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import {useDispatch, useSelector} from "react-redux";
import {getEventPlaceUrl} from "../../util/get-event-place-url";
import InterAxios from "../../Service/InterAxios";
import Cookies from 'universal-cookie';
import {slug} from "../../util/slug";

const cookies = new Cookies();
const getAuthToken = () => cookies.get('authtoken')?.token;
const interAxios = new InterAxios('App', getAuthToken);

const SearchCard  = (props) => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isRender, setIsRender] = useState(false);
  const { eventPlace, searchText } = useSelector(state => state);

  useEffect(() => {
    setIsRender(!isRender)
  }, [searchText]);

  const getReplaceText = (text) => {
    return text.toLowerCase().replaceAll("ö", "o").replaceAll("ü", "u").replaceAll(".", "").replaceAll("í", "i").replaceAll("ó", "o").replaceAll("ú", "u").replaceAll("é", "e").replaceAll("á", "a").replaceAll("ő", "o").replaceAll("ű", "u").replaceAll(",", "").replaceAll(" ", "_")
  }

  const onGetInfospage = (id) => {
    interAxios.get('/infopages/' + id, function(response) {
        if(response.data.children.length > 0){
          navigate('/infosfooldal/' + getReplaceText(response.data.title))
        } else {
          const path = response.data.crumbs.map(c => getReplaceText(c.title)).join('/')
          navigate('/infos/' + path)
        }
      }, function(error) {
        console.log("errorFunction is running: " + error.response?.data)
    });
  }

  return (
    <>
      {props.category === "infopages" ? 
        <div className="aloldal-card-div" onClick={() => onGetInfospage(props.msg.id)}>
            <p>{props.msg.title}</p>
        </div>
      :
        <>
        {props.category === "events" ?
          <div className="search-card-div" onClick={() => {if(props.msg.event_place_id === eventPlace?.id){
            navigate('/programok/programdetailpage/' + props.msg.id + '/' + slug(props.msg.title), {state: {
              programId: props.msg.id,
              program: props.msg,
              categoryName: "Következő programok",
              linkText: "kovetkezoprogramok"
            }}); 
          }else {
            window.open(getEventPlaceUrl('/programok/programdetailpage/' + props.msg.id + '/' + slug(props.msg.title), props.msg.event_place_id));
          }
         
          }}>
            <div className="image-div">
                <img src={props.msg.image} alt=""/>
            </div>
            <div className="datum-div">
                  <span>{moment.unix(props.msg.date).locale('hu').format('MMMM DD., HH:mm')}</span>
              </div>
            <div className="searchcard-text">
              <div className="title-div">
                  <span>{props.msg.title}</span>
              </div>
            </div>
          </div>
          :
          <div className="search-card-div" onClick={() => {
            if(props.msg.event_place_id === eventPlace?.id){
              navigate('/hirek/hirekdetailpage/' + props.msg.id); 
            }else {
              window.open(getEventPlaceUrl('/hirek/hirekdetailpage/' + props.msg.id, props.msg.event_place_id));
            }
              }}>
            <div className="image-div">
                <img src={props.msg.image} alt=""/>
            </div>
            <div className="datum-div">
                <span>{moment.unix(props.msg.date).locale('hu').format('MMMM DD., HH:mm')}</span>
            </div>
            <div className="searchcard-text">
              <div className="title-div">
                  <span>{props.msg.title}</span>
              </div>
        
            </div>
          </div>
        }
        </>
      } 
    </>
  );
};

export default SearchCard ;
