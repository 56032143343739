import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TbKartyaForm from "../components/form-components/TbKartyaForm"
import { MDBContainer } from 'mdb-react-ui-kit';
import tovabb from "../assets/media/icons/tovabb nyil.svg"
import tbkartya from "../assets/media/images/tbkartya.png"
import {useSelector} from "react-redux";

const TbkartyaPage = () => {

  const navigate = useNavigate();
  const { tccontent } = useSelector(state => state);

  useEffect(() => {
    setTimeout(() => window.scrollTo({
      top: 0,
      behavior: "smooth"
    }), 100);
  }, []);

  const scrollToForm = () => {
    window.location.href = `#urlap-section`;
  }

  return (
    <>
    <section className="kapcsolat-map-section tb-kartya" id ="kapcsolat-map-section" >
      <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
        <div className="kapcsolat-map-div">
          <div className="one-col-div">
            <div className="navigate-div" style={{marginTop: 0}}>
              <button onClick={() => {
                navigate('/home')
              }}>Főoldal
              </button>
              <img src={tovabb} alt="tovabb"/>
              <button onClick={() => navigate('/tbkartya')}>Tatabánya Kártya</button>
            </div>

            <div className="up-div mb-0">
              <h3>Tatabánya Kártya</h3>
              <div dangerouslySetInnerHTML={{__html: tccontent}} />
              { /*
              <img className="tbkartya-image" src={tbkartya} alt="tbkartya"/>
              <button className="primary-button" onClick={ scrollToForm }>Igénylőlap kitöltése</button>
              <p className="title-text">Mi a Tatabánya Kártya</p>
              <span>
                A Tatabánya Kártya egy sajátos kedvezménykártya, melynek segítségével:
                <ol>
                  <li value="1">Ingyenes a parkolás a városközpontban kialakított fizetőparkoló övezetben.</li>
                  <li>Kedvezményes belépőjegyet válthat városunk több intézményében, kedvezményesen vásárolhat vagy vehet igénybe szolgáltatásokat a kártya elfogadóhelyi partnereinél.</li>
                </ol>
                A Tatabánya Kártya célja, hogy a helyi lakosok számára előnyt biztosítson városunkban, hogy a tatabányaiak érezzék: itthon vagyunk.
              </span>
              <span className="kiemelt-text mt-5">A Tatabánya Kártya éves díja 2950 Ft az első évre, megújítási költsége 1950 Ft.</span>
              <span className="kiemelt-text mb-5"> A kártya a kiváltástól számított 12 hónapig érvényes.</span>
              <p className="title-text">Parkolás</p>
              <span>Tatabánya útjait és parkolóit nem csak a tatabányaiak, hanem a más településekről érkezők is igénybe veszik. Az utak és parkolók fenntartását ugyanakkor a tatabányai adófizetők finanszírozzák. A közteherviselés kiszélesítése érdekében, a tatabányai lakosok díjmentességének megőrzése mellett, szükséges a fizető parkolás bevezetése a belvárosban. Tatabánya Kártya birtokosok számára azonban a parkolás 2012. július 1. után is ingyenes maradt. A díjmentességhez a kártyával rendelkezőknek a Parkolási Ügyfélszolgálaton 2012. június 1-jét követően regisztráltatniuk kell a járműveket. Ez a regisztráció díjmentes!
                Tatabánya Megyei Jogú Város Önkormányzata a tatabányai állandó lakcímmel rendelkezőknek mentességet biztosít a díjfizetés terhe alól. A lakosok -amennyiben kiváltják a Tatabánya Kártyát- a kártyájukhoz egy saját vagy a velük egy lakcímen élő közeli hozzátartozójuk (aki egyben az üzembentartója a gépjárműnek) regisztrálhatnak. A regisztrációt követően a várakozás minden díjfizető parkolási zónában, minden időszakban ingyenes.
                Fontos! A Tatabánya Kártya kiváltása önmagában még nem jogosít fel díjmentes várakozásra, a díjmentességhez a Parkolási Ügyfélszolgálaton szükséges regisztráltatnia a járművet.
              </span>
              <a href="https://tatabanya.hu/varosunk/parkolas" target="_blank" rel="noreferrer">https://tatabanya.hu/varosunk/parkolas</a>
              <p className="title-text  mt-5">Kedvezmény</p>
              <span>A Tatabánya Kártya segítségével kedvezményes belépőjegyet válthat városunk több intézményében, kedvezményesen vásárolhat vagy vehet igénybe szolgáltatásokat a kártya elfogadóhelyi partnereinél.</span>
              <a href="https://agoranonprofit.hu/wp-content/uploads/2023/09/TB_kartya_Elfogadohelyek_20230928.pdf" target="_blank" rel="noreferrer">Elfogadóhelyek listája</a>
              <span className="mt-3">Legyen Ön is partnerünk, hiszen az elfogadóhelyi partnerek számára a csatlakozás ingyenes. </span>
              <span>Elfogadó partnerré váláshoz az Önkormányzat és a Szolgáltató között Szolgáltatói szerződés megkötése szükséges. A Szolgáltatói szerződés a Kártya Szabályzat részét képező Regisztrációs Űrlap kitöltésével és cégszerű aláírásával jön létre. </span>
              <span>Az aláírt Űrlapot a Tatabánya Megyei Jogú Város Önkormányzata Gazdaságfejlesztő Szervezetéhez (GFSZ Nonprofit Kft., 2800 Tatabánya, Szent Borbála út 1. Tel/fax: 34/785-580, 785-919) kérjük visszaküldeni, melyről az Elfogadó partner/Szolgáltató minden esetben visszaigazolást kap.</span>
              <p className="title-text  mt-5">Tatabánya Kártya igénylése</p>
              <span>Kártyák a Tatabánya Kártya ügyfélközpontban igényelhetők,</span>
              <span className="kiemelt-text mb-2">Személyesen:</span>
              <span>2800 Tatabánya, Szent Borbála tér 1. Vértes Agorája épülete, földszintTatabánya, Szent Borbála tér 1.,</span>
              <span className="kiemelt-text mb-2">Nyitvatartás:</span>
              <span>HÉTFŐ-PÉNTEK: 10:00-19:00</span>
              <span>SZOMBAT: 10:00-17:00</span>
              <span>VASÁRNAP: 10:00-13:00</span>
              <span>EBÉDSZÜNET: 13:00-13:45 </span>
              <span className="kiemelt-text mb-2 mt-3">Interneten keresztül:</span>
              <span>az alábbi űrlap kitöltésével.</span>
              <a href="https://agoranonprofit.hu/wp-content/uploads/2021/08/Kartyaigenyles.docx.pdf" target="_blank" rel="noreferrer">Kitöltési útmutató</a>
              <span className="mt-3">A kártyadíj banki átutalásához szükséges számlaszám:</span>
              <span>MKB Bank 10300002-10592029-49020120</span>
              <span>A kedvezményezett neve: Agora Nonprofit Kft.</span>
              <span>Közlemény: kérjük, a közeleményben a telefonszámát tüntesse fel!</span>
              <span>Megjegyzés rovatban kérjük tüntesse fel a Tatabánya Kártyát igénylő nevét és lakcímét és az igénylés dátumát (abban az esetben is, ha az megegyezik az összeget utaló nevével és az utalás dátumával). </span>*/}
            </div>
          </div>
        </div>
      </MDBContainer>
      <div className="background-div"/>
    </section>
    <section className="urlap-section" id="urlap-section">
      <MDBContainer style={{maxWidth: "1008px"}} className='px-0'>
        <TbKartyaForm />
      </MDBContainer>
    </section>
    </>
  );
};

export default TbkartyaPage;
