const switches = [
    { search: `á`, char: `a` },
    { search: `é`, char: `e` },
    { search: `í`, char: `i` },
    { search: `ú`, char: `u` },
    { search: `ü`, char: `u` },
    { search: `ű`, char: `u` },
    { search: `ó`, char: `o` },
    { search: `ö`, char: `o` },
    { search: `ő`, char: `o` }
];

export const slug = (str) => {
    let out = ``;
    for (const c of str) {
        if (/^[A-Z]$/i.test(c) || /^[0-9]$/i.test(c) || c === `-`) {
            out += c.toLocaleLowerCase();
        }
        const toSwitch = switches.find(s => s.search === c.toLocaleLowerCase());
        if (toSwitch) {
            out += toSwitch.char;
        }
        if (c === ' ') {
            out += `-`;
        }
    }
    return out;
}