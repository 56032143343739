import React, { Component } from "react";
import ReactCrop, {
  makeAspectCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import { MDBBtn,
         MDBModal,
         MDBModalBody,
         MDBModalHeader,
         MDBModalFooter,
         MDBModalDialog,
         MDBModalContent,
         MDBModalTitle,
       } from 'mdb-react-ui-kit';

class CreateImageModal extends Component {

  constructor(props) {
    super(props);
    this.inputFotoRef = React.createRef();
    this.state = {
        src: null,
        crop: {
          unit: 'px',
          x: 0,
          y: 0,
          width: 200,
          height: 200
        }
    }
  }

  componentDidMount() {
    this.onSelectFile(this.props.imageFile)
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width; //1080 / 402
    const scaleY = image.naturalHeight / image.height; //396 / 147
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {

      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({url: this.fileUrl, blob: blob});
      }, 'image/jpeg');
    });
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.setState({crop: undefined})
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = event => {
    if(event.target.naturalWidth > 399 && event.target.naturalHeight > 399){
      const initialCrop = {
        x: 0,
        y: 0,
        height: 200,
        width: 200,
      }

      this.setState({
        imageRef: event.target,
        crop: makeAspectCrop(initialCrop, 1)
      })
    }else{
      this.props.setShowCreateImageModal(false);
      alert("A képnek legalább 400 pixel szélesnek és magasnak kell lennie!")
    }

  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.state.imageRef && crop.width && crop.height) {
      const croppedImage = await this.getCroppedImg(
        this.state.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.onMakeCroppedImage(croppedImage);
      this.setState({ croppedImageUrl: croppedImage.url});
    }
  }

  internalHandleSave = () => {
    this.props.onSelectImage(this.state.croppedImage.blob);
    this.props.setShowCreateImageModal(false);
  }

  internalHandleCancel = () => {
      this.props.setShowCreateImageModal(false);
  }


  onMakeCroppedImage = (croppedImage) => {
    this.setState({croppedImage : croppedImage})
  }

  handleChooseFoto = () => {
    this.inputFotoRef.current.click();
  };

  render() {
    return (
        <MDBModal tabIndex='-1' show={this.props.showCreateImageModal} className="signin-modal">
          <MDBModalDialog centered  scrollable>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Kép szerkesztése</MDBModalTitle>
              </MDBModalHeader>
              <MDBModalBody>

            <div>

            {this.state.src && (
              <div className="d-flex align-items-center justify-content-center pb-4">
              <ReactCrop
                ruleOfThirds
                onChange={this.onCropChange}
                crop={this.state.crop}
                onComplete={this.onCropComplete}
                aspect={1}
              >
                <img alt="Crop" style={{ maxWidth: '100%'}}
                                src={this.state.src}
                                onLoad={this.onImageLoaded}
                                />
              </ReactCrop>
              </div>
            )}
            </div>

            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type="button" className="muster-button transparent" onClick={this.internalHandleCancel}>
                <span>Mégsem</span>
              </MDBBtn>
              {this.state.croppedImage!==undefined && (
                <MDBBtn type="button" className="muster-button elsodleges" onClick={this.internalHandleSave}>
                  <span>Kép létrehozása</span>
                </MDBBtn>
              )}
              
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  }
}

export default CreateImageModal;

