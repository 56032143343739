import {createBrowserRouter} from "react-router-dom";
import HomePage from "./pages/HomePage";
import BemutatkozasPage from "./pages/BemutatkozasPage";
import ElerhetosegekPage from "./pages/ElerhetosegekPage";
import ProgramokListPage from "./pages/ProgramokListPage";
import AllandoProgramokPage from "./pages/AllandoProgramokPage";
import ProgramMusterPage from "./pages/ProgramMusterPage";
import ArchivProgramokPage from "./pages/ArchivProgramokPage";
import ProgramDetailPage from "./pages/ProgramDetailPage";
import HirekListPage from "./pages/HirekListPage";
import HirekFilterPage from "./pages/HirekFilterPage";
import HirekDetailPage from "./pages/HirekDetailPage";
import Kapcsolatpage from "./pages/Kapcsolatpage";
import Irjonnekunkpage from "./pages/Irjonnekunkpage";
import Impresszumpage from "./pages/Impresszumpage";
import TeremberlesPage from "./pages/TeremberlesPage";
import GaleriaKategoriaPage from "./pages/GaleriaKategoriaPage";
import GaleriaPage from "./pages/GaleriaPage";
import HirlevelFeliratkozasPage from "./pages/HirlevelFeliratkozasPage";
import TbkartyaPage from "./pages/TbkartyaPage";
import InfoPage from "./pages/InfoPage";
import InfoPageFooldal from "./pages/InfoPageFooldal";
import OrganizationstructurePage from "./pages/OrganizationstructurePage";
import FelnottkepzesPage from "./pages/FelnottkepzesPage";
import PdfReadPage from "./pages/PdfReadPage";
import SearchPage from "./pages/SearchPage";
import NepmesepontPage from "./pages/NepmesepontPage";
import React from "react";
import Layout from "./components/layout/Layout";
import {homePageLoader} from "./loader/homePageLoader";
import {layoutLoader} from "./loader/layoutLoader";
import {articlesListLoader} from "./loader/articlesListLoader";
import {articleFilterLoader} from "./loader/articleFilterLoader";
import {articleLoader} from "./loader/articleLoader";
import {eventsListLoader} from "./loader/eventsListLoader";
import {recurringEventsListLoader} from "./loader/recurringEventsListLoader";
import {eventsMusterLoader} from "./loader/eventsMusterLoader";
import {archiveEventsLoader} from "./loader/archiveEventsLoader";
import {eventLoader} from "./loader/eventLoader";
import {parentInfopageLoader} from "./loader/parentInfopageLoader";
import {galleryLoader} from "./loader/galleryLoader";
import {infopageLoader} from "./loader/infopageLoader";
import NextExhibitionsListPage from "./pages/NextExhibitionsListPage";
import {nextExhibitionsListLoader} from "./loader/nextExhibitionsListLoader";
import LoginPage from "./pages/LoginPage";
import ProfilePage from "./pages/ProfilePage";
import {tatabanyaCardLoader} from "./loader/tatabanyaCardLoader";

export const router = createBrowserRouter([
    {
        children: [
            {
                element: <HomePage/>,
                loader: homePageLoader,
                path: '/'
            },
            {
                element: <HomePage/>,
                loader: homePageLoader,
                path: '/home'
            },
            {
                element: <BemutatkozasPage/>,
                path: '/bemutatkozas'
            },
            {
                element: <BemutatkozasPage/>,
                path: '/muvhaz/:muvhaz'
            },
            {
                element: <ElerhetosegekPage/>,
                path: '/elerhetosegek'
            },
            {
                element: <ProgramokListPage/>,
                loader: eventsListLoader,
                path: '/fixprogramok/kovetkezoprogramok'
            },
            {
                element: <NextExhibitionsListPage/>,
                loader: nextExhibitionsListLoader,
                path: '/tovabbi-kiallitasok'
            },
            {
                element: <AllandoProgramokPage/>,
                loader: recurringEventsListLoader,
                path: '/fixprogramok/allandoprogramok'
            },
            {
                element: <ArchivProgramokPage/>,
                loader: archiveEventsLoader,
                path: '/fixprogramok/archivum'
            },
            {
                element: <ProgramDetailPage/>,
                loader: eventLoader,
                path: '/programok/programdetailpage/:id/:slug'
            },
            {
                element: <ProgramMusterPage/>,
                loader: eventsMusterLoader,
                path: '/programok/:typeId',
            },
            {
                element: <HirekListPage/>,
                loader: articlesListLoader,
                path: '/hirek/hirek'
            },
            {
                element: <HirekFilterPage/>,
                loader: articleFilterLoader,
                path: '/hirekfilter/:categoryId'
            },
            {
                element: <HirekDetailPage/>,
                loader: articleLoader,
                path: '/hirek/hirekdetailpage/:id',
            },
            {
                element: <Kapcsolatpage/>,
                path: '/kapcsolat'
            },
            {
                element: <Irjonnekunkpage/>,
                path: '/irjonnekunk'
            },
            {
                element: <Impresszumpage/>,
                path: '/impresszum'
            },
            {
                element: <TeremberlesPage/>,
                loader: homePageLoader,
                path: '/rendezvenyszervezes'
            },
            {
                element: <GaleriaKategoriaPage/>,
                path: '/fotogallery'
            },
            {
                element: <GaleriaPage/>,
                loader: galleryLoader,
                path: '/galeriadetailpage/:id'
            },
            {
                element: <HirlevelFeliratkozasPage/>,
                path: '/hirlevelpage'
            },
            {
                element: <TbkartyaPage/>,
                loader: tatabanyaCardLoader,
                path: '/tbkartya'
            },
            {
                element: <InfoPage/>,
                loader: infopageLoader,
                path: '/infos/*',
                shouldRevalidate: () => true
            },
            {
                element: <InfoPageFooldal/>,
                loader: parentInfopageLoader,
                path: '/infosfooldal/:id',
            },
            {
                element: <OrganizationstructurePage/>,
                path: '/organizationstructure'
            },
            {
                element: <FelnottkepzesPage/>,
                path: '/adulteducation'
            },
            {
                element: <PdfReadPage/>,
                path: '/pdfreadpage'
            },
            {
                element: <SearchPage/>,
                path: '/searchpage'
            },
            {
                element: <NepmesepontPage/>,
                path: '/nepmesepont'
            },
            {
                element: <LoginPage/>,
                path: '/belepes'
            },
            {
                element: <ProfilePage/>,
                path: '/profil'
            },
        ],
        element: <Layout/>,
        path: '/',
        loader: layoutLoader
    }
]);