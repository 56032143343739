import {store} from "../redux/store";
import {setGuestEvents} from "../redux/actions/setGuestEvents";
import {listGuestEvents} from "../action/listGuestEvents";
import {listArticles} from "../action/listArticles";
import {setArticles} from "../redux/actions/setArticles";
import {setHomepage} from "../redux/actions/setHomepage";
import {getHomepage} from "../action/getHomepage";
import {setCurrentEventPlaceId} from "../redux/actions/setCurrentEventPlaceId";
import {getCurrentEventPlaceId} from "../util/event-place";

export const homePageLoader = async () => {
    store.dispatch(setCurrentEventPlaceId({ eventPlaceId: getCurrentEventPlaceId() }));
    store.dispatch(setGuestEvents({ guestEvents: await listGuestEvents({ recurring: false }) }));
    store.dispatch(setArticles(await listArticles({ limit: 9 })));
    store.dispatch(setHomepage({ homePage: await getHomepage() }));
    window.homePagePDFLoaded = false;
    return true
}
